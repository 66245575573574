<template>
    <div class="botmain">
        <div class="title">晓知因</div>
        <div class="logo"><img src="@/assets/images/app/logo.png" /></div>
        <div class="hititle" v-if="!fistalk">Hi!</div>
        <div class="content" v-if="!fistalk">
            <div class="stitle">我是您的产业智能专家“晓知因”</div>
            <div class="svalue">我可以为产业提供智能服务。在产业知识库搭建、大模型结合训练以及产业机器人性能测试等方面，我能提供专业知识和帮助。请随时向我提出问题，我会尽我所能为您解答。</div>
        </div>
        <div class="talkbox" id="talkbox">
            <span v-for="(item, i) in talklist" :key="i">
                <div class="useritem" v-if="item.message.length > 0">
                    <div class="userright">
                        <div class="usertext">{{ item.message }}</div>
                    </div>
                </div>
                <div class="aiitem">
                    <div class="airight">
                        <div class="aitext">

                            <el-button type="text"
                                v-if="item.reply_content.result == '' && !item.reply_content.thinktxt"
                                icon="el-icon-loading">生成中..</el-button>
                            <span v-else>
                                <!-- <div>总结：</div> -->
                                <div class="resultbox"
                                    v-if="item.reply_content.thinktxt && item.reply_content.thinktxt.length > 0">
                                    <el-collapse v-model="activeNames">
                                        <el-collapse-item name="1">
                                            <template slot="title">
                                                <i class="el-icon-s-opportunity"></i>深度思考过程（点击可折叠）
                                            </template>
                                            <vue-markdown :source="item.reply_content.thinktxt"
                                                class="custom-markdown"></vue-markdown>
                                        </el-collapse-item>
                                    </el-collapse>

                                    <!-- {{ item.reply_content.thinktxt }} -->
                                </div>
                                <vue-markdown :source="item.reply_content.result"
                                    class="custom-markdown"></vue-markdown>
                            </span>
                        </div>
                    </div>
                </div>
            </span>
        </div>
        <div class="bottombox">
            <div class="btmclickbox">
                <div @click="opensd" :class="issd ? 'skbtn skbtned' : 'skbtn'">深度思考(R1)</div>
                <div class="ceratetalkbtn" @click="createtalk" v-if="fistalk">创建对话</div>
                <div class="stopans" v-if="isstop" @click="stopans">停止生成</div>
            </div>

            <div class="inputmainbox">
                <div :class="isaudio ? 'yybox yyed' : 'yybox'">
                    <img src="@/assets/images/app/mic.png" alt="" @click="audioclick" />
                </div>
                <div class="inputbox" v-if="!isaudio">

                    <div class="center">
                        <textarea id="qmess" v-model="newinputtext" v-on:keydown="keydown" ref="textarea" outline="none"
                            placeholder="点我开始智问…" />
                    </div>
                </div>
                <div class="inputbox" v-else>
                    <input id="qmess" outline="none" type="hidden" placeholder="点我开始智问…" />
                    <div class="audiotip" v-if="!islu" @click="handleStart">轻触开始语音输入</div>
                    <div class="audiotiping" v-else @click="handleStop">已录制{{ recorder.duration.toFixed(2) }}秒，轻触结束
                    </div>
                    <div></div>
                </div>

                <button id="sendbtn" class="sendbtn" @click="keydown(13)">
                    <img src="@/assets/images/app/send.png" alt="" />
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Recorder from 'js-audio-recorder'
import VueMarkdown from 'vue-markdown'
import { baseUrl } from '@/axios/baseUrl'
import Vue from 'vue';
export default {
    name: '',
    components: { VueMarkdown },
    data(props) {
        return {
            fistalk: false,
            aitype: '英鸣产业大模型',
            talklist: props.basetalklist || [],
            hotlists: [],
            conversation_id: null,
            anslist: '',
            aibox: props.aiboxitem || [],
            activeNames: ['1'],
            cjqlist: [],
            recorder: null,
            isstop: false,
            isaudio: false,
            islu: false,
            recording: false, // 是否正在录音
            audioSrc: null, // 存储录音的URL
            isconn: false,
            isshowcw: false,
            eventSource: null,
            cjed: '',
            srcList: [],
            cided: 0,
            hisflag: false,
            talkguanlianid: 0,
            schhistxt: '',
            newinputtext: '',
            newinputtext: '',
            historylist: [],
            historyid: 0,
            height: '30px',
            thinkresult: '',
            issd: false

        }
    },
    props: {
    },
    mounted() {
    },
    watch: {

    },
    methods: {
        copy(text) {
            const textArea = document.createElement('textarea');
            setTimeout(() => {
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();

                try {
                    const successful = document.execCommand('copy');
                    if (successful) {
                        this.$message.success('复制成功');
                    } else {
                        this.$message.error('复制失败');
                    }
                } catch (err) {
                    this.$message.error('复制出现错误', err);
                }

                document.body.removeChild(textArea);
            }, 300);
        },
        opensd() {
            this.issd = !this.issd
        },
        getHeight() {
            console.log('扣1送地狱火');
            setTimeout(() => {
                this.height = calcTextareaHeight(this.$refs.textarea, 1, null).height;
            }, 10)

        },
        backpage() {
            this.hisflag = false
            this.fistalk = false;
        },
        createtalk() {
            const that = this;
            that.talklist = []
            that.talkguanlianid = ''
            that.cided = 0;
            that.fistalk = false
            that.hisflag = false
            // })
        },
        retalk(message) {
            document.getElementById('qmess').value = message;
            setTimeout(() => {
                this.sendmessage()
            }, 200);

        },
        retalksd(message) {

            document.getElementById('qmess').value = message;
            setTimeout(() => {
                this.sendmessage()
                this.issd = true
            }, 500);

        },
        keydown(event) {
            // console.log(event)
            if (event.keyCode === 13 && event.shiftKey) {
                // console.log("按t下了回车键+shift");
                // document.getElementById('qmess').value = document.getElementById('qmess').value + '<br />'
                // event.preventDefault(); // 阻止默认的换行行为

            } else if (event.keyCode === 13) {
                // console.log("按t下了回车键");
                setTimeout(() => {
                    this.sendmessage()
                    this.newinputtext = ''
                }, 100)
            }
            else if (event === 13) {
                setTimeout(() => {
                    this.sendmessage()
                    this.newinputtext = ''
                }, 100)
            }
        },
        audioclick() {
            this.isaudio = !this.isaudio
        },

        // 开始录音
        handleStart() {
            this.recorder = new Recorder()
            Recorder.getPermission().then(() => {
                console.log('开始录音')
                this.islu = true;
                this.recorder.start() // 开始录音
            }, (error) => {
                this.$message({
                    message: '请先允许该网页使用麦克风',
                    type: 'info'
                })
                console.log(`${error.name} : ${error.message}`)
            })
        },

        handleStop() {
            console.log('停止录音')
            this.islu = false;
            this.recorder.stop() // 停止录音
            setTimeout(() => {
                this.uploadRecord()
            }, 200);
        },

        uploadRecord() {
            if (this.recorder == null || this.recorder.duration === 0) {
                this.$message({
                    message: '请先录音',
                    type: 'error'
                })
                return false
            }
            this.recorder.pause() // 暂停录音
            this.timer = null
            console.log('上传录音')// 上传录音
            let xhr = new XMLHttpRequest();
            xhr.open('POST', baseUrl + '/audioapi/asr', true);
            const formData = new FormData()
            const blob = this.recorder.getWAVBlob()// 获取wav格式音频数据
            // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
            const newbolb = new Blob([blob], { type: 'audio/wav' })
            const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
            formData.append('file', fileOfBlob)
            // const url = window.URL.createObjectURL(fileOfBlob)
            // this.src = url
            xhr.send(formData);
            const that = this;
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const res = JSON.parse(xhr.responseText);
                    console.log(res)
                    if (res.text == '') return;
                    setTimeout(() => {
                        document.getElementById('qmess').value = res.text;
                        that.sendmessage()
                    }, 100);
                }
            }

        },
        gotalk(e, item) {
            console.log(e, item)
            if (item) this.cjed = item.title;
            setTimeout(() => {
                document.getElementById('qmess').value = e;
                this.sendmessage()
                this.cjclick(item)
            }, 500);
            this.fistalk = true;
            this.$emit('changeaiflag', true)

        },
        gotk(e) {
            this.cjed = e.title
            this.cjqlist = e.qlist || [];
            this.fistalk = true;
            this.$emit('changeaiflag', true)
        },
        changegpttype(e) {
            // console.log(e)
            this.aitype = e

        },
        gofurpage() {
            // router.push({ name: 'Furniture' })
        },
        async connectSever(input) {
            // const eventSource = new EventSource(`http://47.96.179.72:11454/chat/gygpt_chat?query=${input}&model_name=${this.aitype || '英鸣产业大模型'}&temperature=0.7&mode=绿色食品GPT`, {
            //   headers: {
            //     'Accept': 'text/event-stream '
            //   }
            // })
            const that = this;
            that.talklist[that.talklist.length - 1].reply_content.result = ''
            that.talklist[that.talklist.length - 1].reply_content.thinktxt = ''
            console.log('进来了几次')
            const modeltype = this.issd ? 'deepseek-r1:70b' : 'qwen2-72b';
            const userid = '99'
            if (this.talkguanlianid == 0) {
                that.eventSource = new EventSource(`${baseUrl}/queapi/robot_writing/gygpt_chat?robotId=13&query=${input}&userId=${userid}&modelName=${modeltype}`, {

                })
            } else {
                that.eventSource = new EventSource(`${baseUrl}/queapi/robot_writing/gygpt_chat?robotId=13&query=${input}&robotQAHistoryId=${this.talkguanlianid}&userId=${userid}&modelName=${modeltype}`, {

                })
            }

            that.eventSource.addEventListener('open', (event) => {
                that.isconn = true;
                console.log('连接已建立', JSON.stringify(event))

            })
            let thinkend = 0;
            that.eventSource.onmessage = (event) => {
                that.isstop = true;
                // console.log(event.data)
                const data = JSON.parse(event.data)

                // console.log(data)
                if (!data) {
                    that.talklist[that.talklist.length - 1].reply_content.result = ''
                    that.talklist[that.talklist.length - 1].reply_content.thinktxt = ''
                    return
                }
                if (that.talklist[that.talklist.length - 1].reply_content.result == '') {
                    that.talklist[that.talklist.length - 1].reply_content.result = ''
                }
                if (data.id) {
                    this.historyid = data.id
                }

                that.isshowcw = true

                if (data.answer == '[DONE]') {
                    console.log(that.talklist[that.talklist.length - 1].reply_content.result)
                    if (that.talklist[that.talklist.length - 1].reply_content.result == '') {
                        that.talklist[that.talklist.length - 1].reply_content.result = '网络连接超时，请稍后再试'
                    } else {
                        that.talklist[that.talklist.length - 1].reply_content.relatedquestion = data.relatedquestion;
                    }

                    return;
                }
                if (data.id) {
                    this.talkguanlianid = data.id
                    return;
                }

                // .replace('<think>', '<div style="color:#6c6c6c;background-color: #f2f2f2;padding:10px">').replace('</think>', '</div>')
                if (data.answer.includes('</think>')) {
                    thinkend = 2
                    return;
                }
                if (data.answer.includes('<think>')) {
                    thinkend = 1
                    return;
                }
                // if (!this.thinkend && this.issd) {
                //   this.thinkresult = this.thinkresult + data.answer;
                //   return
                // }

                // console.log(thinkend, that.talklist[that.talklist.length - 1].reply_content.thinktxt)
                that.talklist[that.talklist.length - 1].reply_content.imagereplys = data.imagereplys ? data.imagereplys : data.imagereply ? [data.imagereply] : null
                if (thinkend == 1 && this.issd) {
                    Vue.set(that.talklist[that.talklist.length - 1].reply_content, 'thinktxt', that.talklist[that.talklist.length - 1].reply_content.thinktxt + data.answer)
                    // that.talklist[that.talklist.length - 1].reply_content.thinktxt = that.talklist[that.talklist.length - 1].reply_content.thinktxt + data.answer;
                } else {
                    that.talklist[that.talklist.length - 1].reply_content.result = that.talklist[that.talklist.length - 1].reply_content.result + data.answer;
                }

                this.$forceUpdate();

                if (data.answer.includes('\n') || that.talklist[that.talklist.length - 1].reply_content.result.length / 40 == 0 || that.talklist[that.talklist.length - 1].reply_content.thinktxt.length / 40 == 0 || data.answer.includes('图') || data.answer.includes('，')) {
                    that.$nextTick(() => {
                        const element = document.getElementById('talkbox')
                        element.scrollTo({
                            x: 0,
                            top: Number.MAX_SAFE_INTEGER,
                            behavior: 'smooth'
                        })
                    })
                }


            }
            that.eventSource.addEventListener('eventName', (event) => {
                console.log('Received event:', event.data);
            });
            that.eventSource.onerror = (event) => {
                that.isconn = false;
                console.log('连接已断开')
                console.log(that.historyid)
                that.isstop = false;
                that.isshowcw = false;
                that.$nextTick(() => {
                    that.isshowcw = true;
                    setTimeout(() => {
                        const element = document.getElementById('talkbox')
                        element.scrollTo({
                            x: 0,
                            top: Number.MAX_SAFE_INTEGER,
                            behavior: 'smooth'
                        })
                    }, 300);


                })
                // setTimeout(() => {

                //   that.isshowcw = false
                // }, 1000);    .replace('http://47.96.179.72:11454/','/klgapi/')
                console.log(that.talklist[that.talklist.length - 1].reply_content)
                if (that.talklist[that.talklist.length - 1].reply_content.imagereplys) {
                    that.talklist[that.talklist.length - 1].reply_content.imagereplys.forEach(item => {
                        that.srcList.push(item.replace('http://47.96.179.72:11454/', 'https://gpt-dptk.web.guanyingis.com/klgapi/'))
                    })
                    // that.srcList.push(...that.talklist[that.talklist.length - 1].reply_content.resultimgs)
                }
                console.log(that.srcList)
                that.eventSource.close()
            }
        },
        stopans() {
            // this.eventSource.stop()
            this.eventSource.close()
            this.isstop = false
            this.isshowcw = false;
            this.isconn = false;
        },
        zhuiitemclick(e) {
            console.log(e)
            this.gotalk(e)
        },
        sendmessage() {
            // document.getElementsByClassName('aibotbox')[0].style.display = 'none'
            // document.getElementsByClassName('aitalkbox')[0].style.height = 'calc(100% - 20px)'
            // document.getElementsByClassName('aihotbox')[0].style.marginTop = '150px'
            if (this.isconn) {
                return;
            }
            if (!this.fistalk) {
                this.fistalk = true
            }
            console.log('first')
            const message = document.getElementById('qmess').value
            if (message == '') return;
            this.talklist.push({
                message: message,
                reply_content: {
                    result: ''
                }
            })
            const element = document.getElementById('talkbox')
            setTimeout(() => {
                element.scrollTo({
                    x: 0,
                    top: element.scrollHeight,
                    behavior: 'smooth'
                })

                document.getElementById('qmess').value = ''
            }, 300)
            this.$emit('talklistchange', this.talklist)
            this.connectSever(message);

        },
    }
}
</script>
<style lang='less' scoped>
.botmain {
    height: 100%;
    width: 100%;
    position: relative;
    background-image: url('../assets/images/app/bg.png');
    background-size: cover;
    overflow: hidden;

    .title {
        display: flex;
        justify-content: center;
        font-size: 18px;
        padding-top: 40px;
    }

    .logo {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100px;

        img {
            width: 100px;
        }
    }

    .hititle {
        font-size: 24px;
        font-weight: bold;
        margin: 10px 20px;
    }

    .content {
        margin: 10px 20px;

        .stitle {
            font-size: 18px;
        }

        .svalue {
            margin-top: 15px;
            font-size: 16px;
            color: #00000060;
        }
    }

    .talkbox {
        width: 100%;
        height: calc(100% - 290px);
        overflow-y: auto;
        overflow-x: hidden;

        .aiitem {
            display: flex;
            width: calc(100% + 0px);
            position: relative;
            margin-left: 0px;


            .airight {
                min-width: 180px;
                max-width: calc(100% - 20px);
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding-left: 10px;
                text-align: left;
                margin-bottom: 10px;
                border-radius: 0px 12px 12px 12px;
                position: relative;



                .name {
                    font-size: 18px;
                    color: #333333;
                    margin-bottom: 10px;

                    span {
                        font-size: 12px;
                        background: #F8F8F9;
                        border-radius: 6px;
                        padding: 4px 8px;
                        margin-left: 10px;
                    }
                }

                .aitext {
                    // width: 500px;
                    width: 100%;
                    color: #333333;
                    padding: 10px;
                    font-size: 14px;

                    .loadinggif {
                        height: 52px;

                        img {
                            height: 52px;
                        }
                    }

                    /deep/ .el-collapse-item__header,
                    /deep/ .el-collapse-item__wrap,
                    /deep/ .el-collapse-item__content {
                        background-color: #f2f2f270 !important;
                        font-size: 14px;
                        padding-bottom: 0;

                    }

                    /deep/ .el-collapse-item__header {
                        color: #409eff
                    }

                    /deep/ .el-collapse-item__content {
                        color: #8b8b8b !important;
                        border-left: 1px solid #bdbdbd;
                        padding-left: 10px;
                    }

                    .resultbox {
                        color: #262626;
                        background-color: #f2f2f270;
                        padding: 10px;
                        margin-bottom: 20px;
                        border-radius: 12px;
                    }

                    .custom-markdown code,
                    .custom-markdown /deep/ pre,
                    .custom-markdown pre code {
                        white-space: break-spaces;
                        word-break: break-all;
                        font-size: 16px;
                        /* 换行 */
                    }


                }


            }
        }

        .useritem {
            position: relative;
            color: white;
            width: calc(100% - 10px);
            display: flex;
            justify-content: flex-start;
            flex-direction: row-reverse;



            .userright {
                // background: #FF009D;
                padding-right: 0px;
                text-align: left;
                float: right;
                margin: 10px 0;
                max-width: 800px;
                margin-left: 40px;

                .usertext {
                    color: #333;
                    border-radius: 12px 0px 12px 12px;
                    font-size: 14px;
                    padding: 10px 20px;
                    white-space: pre-wrap;
                }
            }
        }
    }

    .bottombox {
        width: 100%;
        height: 50px;
        display: flex;
        position: absolute;
        left: 0;
        bottom: 34px;

        .btmclickbox {
            position: absolute;
            left: 0;
            width: 100%;
            padding: 0 20px;
            bottom: 58px;
            display: flex;
            justify-content: space-between;

            .ceratetalkbtn {
                background-color: #0389F8;
                color: #fff;
                width: 100px;
                text-align: center;
                font-size: 14px;
                line-height: 30px;
                border-radius: 20px;
                height: 30px;
                cursor: pointer;
            }

            .stopans {
                background-color: #ff009d;
                color: #fff;
                width: 100px;
                text-align: center;
                font-size: 14px;
                line-height: 30px;
                border-radius: 20px;
                height: 30px;
                cursor: pointer;
            }

            .skbtn {
                width: 100px;
                background: #0042CD20;
                padding: 4px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #3166D8;
                border-radius: 20px;
                font-size: 12px;
                cursor: pointer;

            }

            .skbtned {
                background: #0389F8;
                color: white;
            }
        }



        .inputmainbox {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .yybox {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #fff;
                margin: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 25px;
                }


            }

            .yyed {
                background: #4077EE;
            }

            .sendbtn {
                width: 40px;
                height: 40px;
                margin: 0 10px;
                border-radius: 50%;
                background: #4077EE;
                display: flex;
                border: 0;
                justify-content: center;
                align-items: center;

                img {
                    width: 25px;
                    margin-left: -3px;
                }
            }

            .inputbox {
                width: calc(100% - 120px);
                display: flex;
                background-color: #fff;
                border-radius: 20px;
                align-items: center;
                height: 40px;

                .center {
                    width: 100%;
                    height: 100%;

                    textarea {
                        width: 100%;
                        height: 100%;
                        border: 0;
                        outline: none;
                        color: #333;
                        font-size: 16px;
                        font-family: Alibaba PuHuiTi;
                        resize: none;
                        background-color: transparent;
                        white-space: pre-wrap;
                        white-space: pre-wrap;
                        line-height: 40px;
                        padding-left: 12px;
                    }

                    input:focus {
                        outline: none;
                    }

                    input::placeholder {
                        color: #333;
                        font-weight: 100;
                    }
                }

                .audiotip {
                    width: calc(100% - 0px);
                    color: #444;
                    height: 70%;
                    border-radius: 20px;
                    text-align: center;
                    line-height: 30px;
                    font-size: 14px;
                    letter-spacing: 5px;
                }

                .audiotiping {
                    width: calc(100% - 0px);
                    color: #444;
                    height: 70%;
                    border-radius: 20px;
                    text-align: center;
                    line-height: 30px;
                    font-size: 14px;
                }
            }
        }


    }

    /deep/ .custom-markdown p {
        line-height: 22px;
    }
}
</style>